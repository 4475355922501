// material
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
import {
  LandingHero,
  // LandingMinimal,
  LandingDarkMode,
  LandingThemeColor,
  LandingPricingPlans,
  LandingHowDoesItWork,
  LandingWhatWithExample,
  LandingProblemsSolved
  // LandingWhat
} from '../components/_external-pages/landing';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  height: '100%'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

// Uses fluent imports
// eslint-disable-next-line import/no-unused-modules
export default function LandingPage() {
  return (
    <RootStyle title="Send and Receive Files a better way | CompliShare" id="move_top">
      <LandingHero />
      <ContentStyle>
        <LandingWhatWithExample />
        <LandingHowDoesItWork />
        <LandingThemeColor />
        <LandingDarkMode />
        <LandingProblemsSolved />
        {/* <LandingMinimal />
        <LandingWhat /> */}
        <LandingPricingPlans />
      </ContentStyle>
    </RootStyle>
  );
}
