// material
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';

import { PrivacyPolicyContent } from '../components/_external-pages/legal';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11)
  }
}));

// ----------------------------------------------------------------------
// Uses fluent imports
// eslint-disable-next-line import/no-unused-modules
export default function PrivacyPolicy() {
  return (
    <RootStyle title="Privacy Policy | CompliShare">
      <PrivacyPolicyContent />
    </RootStyle>
  );
}
