// material
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, Stack } from '@mui/material';
// utils
//
import { varFadeInUp, varFadeInRight, MotionInView } from '../../animate';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}));

// ----------------------------------------------------------------------

export default function AboutWhat() {
  const textTyporgrapyhStyles = {
    color: (theme: any) => (theme.palette.mode === 'light' ? 'text.secondary' : 'common.white')
  };
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12}>
                <MotionInView variants={varFadeInUp}>
                  <Typography variant="h2">Who is</Typography>
                  <Typography variant="h2" sx={{ color: 'primary.main' }}>
                    CompliShare?
                  </Typography>
                </MotionInView>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <MotionInView variants={varFadeInRight}>
              <Stack spacing={2}>
                <Typography sx={textTyporgrapyhStyles}>
                  We are a team of designers and software engineers with a passion for improving
                  design processes through technology. We want to build tools and solutions for
                  digital design teams that make the design experience seamless and efficient.
                </Typography>
                <Typography sx={textTyporgrapyhStyles}>
                  CompliShare came out of the fustration that comes with requesting files and
                  receiving the wrong things, leading to double handling of files and needless time
                  wasted.
                </Typography>
                <Typography sx={textTyporgrapyhStyles}>
                  We have built CompliShare to shift the validation of the files to the uploader and
                  the basic compliance requirements are communicated at the time of the upload.
                </Typography>
                <Typography sx={textTyporgrapyhStyles}>
                  Our service has been built to suit the needs of design agencies, design teams and
                  independent professionals.
                </Typography>
              </Stack>
            </MotionInView>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
