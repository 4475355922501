// ----------------------------------------------------------------------

export const PATH_PAGE = {
  pricing: '/pricing',
  about: '/about-us',
  contact: '/contact-us',
  privacy: '/privacy',
  TOS: '/terms-of-service',
  cookies: '/cookies',
  userGuidelines: '/user-guidelines',
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = 'https://app.complishare.com';
export const SIGNIN_PATH_DASHBOARD = 'https://app.complishare.com/auth/login';
export const REGISTER_PATH_DASHBOARD = 'https://app.complishare.com/auth/register';
