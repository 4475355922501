// material
import { Container, Typography, Grid } from '@mui/material';
//
import { varFadeInUp, MotionInView } from '../../animate';

// ----------------------------------------------------------------------

export default function AboutVision() {
  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Grid item xs={12}>
        <MotionInView variants={varFadeInUp} sx={{ textAlign: 'center', marginBottom: 2 }}>
          <Typography display="inline" variant="h2">
            Our{' '}
          </Typography>
          <Typography display="inline" variant="h2" sx={{ color: 'primary.main' }}>
            Vision?
          </Typography>
        </MotionInView>
        <MotionInView variants={varFadeInUp}>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            To enable digital designers to experience seamless and efficient experience when working
            with others.
          </Typography>
        </MotionInView>
      </Grid>
    </Container>
  );
}
