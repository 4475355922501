// material
import { Grid, Container } from '@mui/material';
// components
import PricingPlanCard from './PricingPlanCard';
import { PLAN_TYPE } from './PLAN_TYPE';
//

// ----------------------------------------------------------------------

const PLANS = [
  {
    subscription: 'Starter',
    // icon: <PlanFreeIcon />,
    price: 0,
    caption: 'For creatives',
    lists: [
      { text: 'Receive images up to 15mb', isAvailable: true },
      { text: 'Uploads expire after 14 days', isAvailable: true },
      { text: 'Storage: (temporary) Up to 1GB', isAvailable: true },
      { text: 'Multi User', isAvailable: false }
    ],
    planType: PLAN_TYPE.STARTER,
    planLookupKey: ''
  },
  {
    subscription: 'Professional',
    price: 40,
    caption: 'For professional creatives and small businesses',
    lists: [
      { text: 'Receive images up to 2GB', isAvailable: true },
      { text: 'Uploads never expire', isAvailable: true },
      { text: 'Storage: Up to 100GB', isAvailable: true },
      { text: 'Multi User', isAvailable: false }
    ],
    planType: PLAN_TYPE.PROFESSIONAL,
    planLookupKey: 'price_1Keel8HgIayQ1mJno0BITP8B'
  },
  {
    subscription: 'Team',
    price: 120,
    caption: 'For businesses, agencys & studios',
    lists: [
      { text: 'Receive images up to 2GB', isAvailable: true },
      { text: 'Uploads never expire', isAvailable: true },
      { text: 'Storage: Up to 1TB', isAvailable: true },
      { text: 'Team File Sharing', isAvailable: true },
      { text: 'Multi User (3 Seats included)', isAvailable: true },
      { text: 'Additional Seats & Storage Available on request', isAvailable: true }
    ],
    planType: PLAN_TYPE.PREMIUM,
    planLookupKey: 'price_1Keel2HgIayQ1mJnkvZOVh3Y'
  }
];

// ----------------------------------------------------------------------

// Uses fluent imports
// eslint-disable-next-line import/no-unused-modules
const PricingTable = () => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {PLANS.map((card, index) => (
          <Grid item xs={12} md={4} key={card.subscription}>
            <PricingPlanCard card={card} index={index} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PricingTable;
