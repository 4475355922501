// material
import { useTheme, styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
//
import { varFadeIn, varFadeInUp, MotionInView, varFadeInDown } from '../../animate';
import { PricingTable } from './../pricing';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: '#f4f6f8',
  paddingTop: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(15)
  }
}));

// ----------------------------------------------------------------------

export default function LandingPricingPlans() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: 5, textAlign: 'center' }}>
          <MotionInView variants={varFadeInUp}>
            <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.secondary' }}>
              pricing plans
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInDown}>
            <Typography variant="h2" sx={{ mb: 3 }}>
              The right plan for your business
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInDown}>
            <Typography
              sx={{
                color: isLight ? 'text.secondary' : 'text.primary'
              }}
            >
              Save time by receiving files in a better way with CompliShare
            </Typography>
          </MotionInView>
        </Box>

        <PricingTable />

        <MotionInView variants={varFadeIn}>
          <Box sx={{ p: 5, mt: 20, mb: 5, textAlign: 'center' }}>
            <MotionInView variants={varFadeInDown}>
              <Typography variant="h3">Still have questions?</Typography>
            </MotionInView>

            <MotionInView variants={varFadeInDown}>
              <Typography sx={{ mt: 3, mb: 5, color: 'text.secondary' }}>
                Please reach out and get in touch, so we can answer any of your questions.
              </Typography>
            </MotionInView>

            <Button
              size="large"
              variant="contained"
              href="mailto:support@complishare.com?subject=[Feedback] from Customer"
            >
              Contact us
            </Button>
          </Box>
        </MotionInView>
      </Container>
    </RootStyle>
  );
}
