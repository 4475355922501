import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
// routes
import { PATH_PAGE, SIGNIN_PATH_DASHBOARD, REGISTER_PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Discover',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/'
  },
  { title: 'Plans & Pricing', path: PATH_PAGE.pricing },
  { title: 'About us', path: PATH_PAGE.about },
  { title: 'Contact us', path: PATH_PAGE.contact },

  { title: 'Log In', path: SIGNIN_PATH_DASHBOARD },
  { title: 'Sign Up', path: REGISTER_PATH_DASHBOARD }
  // { title: 'FAQs', path: PATH_PAGE.faqs },
];

export default menuConfig;
