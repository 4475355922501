import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// ----------------------------------------------------------------------
import LandingPage from '../pages/LandingPage';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Pricing from '../pages/Pricing';
import Privacy from '../pages/PrivacyPolicy';
import Terms from '../pages/TermsOfService';
import CookiesPolicy from '../pages/CookiesPolicy';
import Page500 from '../pages/Page500';
import NotFound from '../pages/Page404';
import UserGuidelines from '../pages/UserGuidelines';

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <LandingPage /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'privacy', element: <Privacy /> },
        { path: 'terms-of-service', element: <Terms /> },
        { path: 'cookies', element: <CookiesPolicy /> },
        { path: 'user-guidelines', element: <UserGuidelines /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
