// material
import { styled } from '@mui/material/styles';
import { Grid, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { ContactForm } from '../components/_external-pages/contact';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11)
  }
}));

// ----------------------------------------------------------------------
// Uses fluent imports
// eslint-disable-next-line import/no-unused-modules
export default function Contact() {
  return (
    <RootStyle title="Contact us | CompliShare">
      <Container sx={{ my: 10 }}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={12}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
