// material
import { styled } from '@mui/material/styles';
import { Stack, Box, Grid, Container, Typography } from '@mui/material';
// utils
//
import { MotionInView, varFadeInDown, varFadeInUp } from '../../animate';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}));

// ----------------------------------------------------------------------

export default function LandingWhat() {
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: { xs: 10 } }}>
          <MotionInView variants={varFadeInDown}>
            <Typography variant="h2" sx={{ textAlign: 'center', mb: 3 }}>
              Let CompliShare request and check images for your design team
            </Typography>
          </MotionInView>
        </Box>
        <Grid item xs={12}>
          <MotionInView variants={varFadeInUp}>
            <Stack spacing={2}>
              <Box>
                <Typography fontWeight={'bold'} sx={{ color: 'primary.main' }} display="inline">
                  CompliShare{' '}
                </Typography>
                <Typography
                  display="inline"
                  fontWeight={'bold'}
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'
                  }}
                >
                  is a specialized file sharing platform that provides a new and improved way for
                  designers to receive images from clients and customers.
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'
                }}
              >
                By checking the images when the client uploads them instead of after you receive
                them. It eliminates needless emails, reminders and general back and forth with
                clients to send the images you need to do your design.
              </Typography>
            </Stack>
          </MotionInView>
        </Grid>
      </Container>
    </RootStyle>
  );
}
