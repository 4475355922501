// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Grid, Card, Container, Typography, Button, Avatar } from '@mui/material';
// utils
//
import { varFadeInUp, MotionInView, varFadeInDown } from '../../animate';

import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../SvgIconStyle';
// import Image from '../../Image';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }: any) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}));

const CARDS = [
  {
    icon: 'mdi:email-send-outline',
    title: 'Create & send request for images',
    description:
      'Crete a request with your requirements and send a link to your intended recipient',
    callToAction: (
      <Button size="large" variant="contained" component={Link} href={PATH_DASHBOARD}>
        Create Request
      </Button>
    )
  },
  {
    icon: 'mdi:arrow-collapse-up',
    title: 'Recipient uploads requested images',
    description:
      'Recipient follows the link and visits the request and uploads the images following the specified requirements'
  },
  {
    icon: 'mdi:image-search-outline',
    title: 'Complishare checks the uploaded images',
    description:
      'Complishare checks the images and asks the recipient to fix any incorrect images e.g. wrong file type, dimensions etc.',
    callToAction: (
      <Typography sx={{ color: 'text.secondary', mb: 5 }} gutterBottom>
        Once Uploaded, anyone in your team can download the requested files at any time.
      </Typography>
    )
  }
];

const CardStyle = styled(Card)(({ theme }: any) => {
  const shadowCard = (opacity: number) =>
    theme.palette.mode === 'light'
      ? alpha(theme.palette.grey[700], opacity)
      : alpha(theme.palette.common.black, opacity);

  return {
    maxWidth: 380,
    minHeight: 470,
    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(5, 5, 0),
    boxShadow: `-40px 40px 80px 0 ${shadowCard(0.7)}`,
    backgroundColor: theme.palette.background.paper,
    '&:before': {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      content: "''",
      margin: 'auto',
      position: 'absolute',
      width: 'calc(100% - 40px)',
      height: 'calc(100% - 40px)',
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: theme.palette.background.paper,
      boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`
    },
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    }
  };
});

// ----------------------------------------------------------------------

export default function LandingHowDoesItWork() {
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: { xs: 10 } }}>
          <MotionInView variants={varFadeInDown}>
            <Typography variant="h2" sx={{ textAlign: 'center', mb: 3 }}>
              How CompliShare works
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center', mb: 3 }}>
              Have you ever had a client or customer send you images that cant be used in design?
              this impacts design teams by blocking projects, impacting deadlines, reducing
              productivity and consumes design time by requiring designers to re-request images that
              can be used. CompliShare has solved this preventable problem and provides the benefit
              to any design team that handles digital files
            </Typography>
          </MotionInView>
          {/* to prevent the team receiving images that cant be used in their designs. */}
          {/* <MotionInView variants={varFadeInUp}>
                <Typography
                  sx={{
                    mb: 5,
                    color: isLight ? 'text.secondary' : 'common.white'
                  }}
                >
                  Reduce the amount of time your design team spends tracking down files, sending
                  follow up emails and general back and forth just to get the digital assets they
                  need
                  
                </Typography>
              </MotionInView> */}
        </Box>

        <Grid container sx={{ mb: { xs: 10 } }} spacing={4}>
          {CARDS.map((card, index) => (
            <Grid key={card.title} item xs={12} md={4}>
              <MotionInView variants={varFadeInUp}>
                <CardStyle>
                  <Box sx={{ position: 'relative' }}>
                    <SvgIconStyle
                      src="https://minimal-assets-api-dev.vercel.app/assets/icons/shape-avatar.svg"
                      sx={{
                        width: 120,
                        height: 50,
                        zIndex: 10,
                        left: 0,
                        right: 0,
                        bottom: -15,
                        mx: 'auto',
                        position: 'absolute',
                        color: 'background.paper'
                      }}
                    />
                    <Avatar
                      alt={card.title}
                      sx={{
                        width: 38,
                        height: 38,
                        zIndex: 11,
                        left: 0,
                        right: 0,
                        bottom: -10,
                        mx: 'auto',
                        position: 'absolute',
                        bgcolor: 'primary.main',
                        color: 'common.white'
                      }}
                    >
                      {index + 1}
                    </Avatar>
                  </Box>
                  <Typography sx={{ color: 'primary.main', mt: 5, mb: 2 }}>
                    <Icon icon={card.icon} height="50" width="50" color="" />
                  </Typography>
                  <Typography variant="h5" paragraph>
                    {card.title}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                    {card.description}
                  </Typography>
                  {card.callToAction}
                </CardStyle>
              </MotionInView>
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  );
}
